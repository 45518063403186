import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { SystemMessage, themes } from '@victorops/victoria'

import MaintenanceModeBanner from 'components/maintenance-mode/banner/gmm-banner'
import OfflineBanner from 'components/notifications/offline-banner'

class BannerContainer extends PureComponent {
  render() {
    const {
      maintenance,
      maintenanceModeBannerTop,
      offlineBannerTop,
      openBanners,
      pauseBannerTop,
    } = this.props

    return (
      <Fragment>
        {openBanners.includes('offline') ? (
          <OfflineBanner bannerTop={offlineBannerTop} />
        ) : null}
        {openBanners.includes('maintenance') ? (
          <MaintenanceModeBanner
            bannerTop={maintenanceModeBannerTop}
            maintenance={maintenance}
          />
        ) : null}
        {openBanners.includes('pause') ? (
          <ThemeProvider theme={themes.voLight}>
            <SystemMessage
              bannerStyle={{
                height: '32px',
                minHeight: '32px',
                position: 'absolute',
                top: pauseBannerTop,
                zIndex: 3000,
              }}
              isPaused={
                window.VO_CONFIG.activeIncidentCount >=
                window.VO_CONFIG.autoPauseThreshold
              }
              modalStyle={{ zIndex: 9999 }}
            />
          </ThemeProvider>
        ) : null}
      </Fragment>
    )
  }
}

// Calculate where the top of each banner lies based on how many banners are open
// and whether the org is a trial org
export function getBannerTops(openBanners) {
  const isTrialOrg = window.VO_CONFIG.billing.state === 'trial'

  const offlineBannerHeight = 32
  const openBannerContainerHeight = openBanners.length * 32
  const trialOrgBannerHeight =
    isTrialOrg && document.querySelector("div[data-ext='vo.oi.trial-banner']")
      ? document.querySelector("div[data-ext='vo.oi.trial-banner']")
          .offsetHeight
      : 0
  const navBarHeight = document.querySelector("nav[data-ext='navbar']")
    ? document.querySelector("nav[data-ext='navbar']").offsetHeight
    : 0

  // Counting the old banner count under the old notification-banner container
  // because I was paranoid about any other banners I wasn't aware of
  // but it should just be the control call banner
  const oldBannerCount = document.querySelector('#notification-banner')
    ? document.querySelector('#notification-banner').childElementCount
    : 0
  const oldBannerHeight = oldBannerCount * navBarHeight

  const newApplicationPaneTop = String(
    trialOrgBannerHeight +
      openBannerContainerHeight +
      oldBannerHeight +
      navBarHeight +
      'px'
  )

  const controlCallBannerTop = String(
    trialOrgBannerHeight + openBannerContainerHeight + navBarHeight + 'px'
  )

  const offlineBannerTop = String(navBarHeight + trialOrgBannerHeight + 'px')

  const maintenanceModeBannerTop = openBanners.includes('offline')
    ? navBarHeight + offlineBannerHeight + trialOrgBannerHeight
    : navBarHeight + trialOrgBannerHeight

  const pauseBannerTop = openBanners.includes('maintenance')
    ? maintenanceModeBannerTop + 32
    : maintenanceModeBannerTop

  return {
    controlCallBannerTop,
    maintenanceModeBannerTop: String(maintenanceModeBannerTop) + 'px',
    newApplicationPaneTop,
    offlineBannerTop,
    pauseBannerTop: String(pauseBannerTop) + 'px',
  }
}

function mapStateToProps(state) {
  const openBanners = []

  if (
    state.orgstate &&
    (state.orgstate.get('COMPANY_SUSPENDED') || !state.maintenance.isEmpty())
  ) {
    openBanners.push('maintenance')
  }

  if (state.orgstate && state.orgstate.get('COMPANY_OFFLINE')) {
    openBanners.push('offline')
  }

  const { activeIncidentCount, autoPauseThreshold } = window.VO_CONFIG
  const AUTO_PAUSE_WARNING_THRESHOLD = 100
  const isNearPause =
    activeIncidentCount >= autoPauseThreshold - AUTO_PAUSE_WARNING_THRESHOLD
  if (isNearPause) {
    openBanners.push('pause')
  }

  const {
    controlCallBannerTop,
    maintenanceModeBannerTop,
    newApplicationPaneTop,
    offlineBannerTop,
    pauseBannerTop,
  } = getBannerTops(openBanners)

  // Move the application pane and the control call banner
  document.querySelector(
    '#notification-banner'
  ).style.top = controlCallBannerTop
  document.querySelector('#application-panes').style.top = newApplicationPaneTop

  return {
    maintenance: state.maintenance,
    maintenanceModeBannerTop,
    offlineBannerTop,
    openBanners,
    pauseBannerTop,
  }
}

export default connect(mapStateToProps)(BannerContainer)
