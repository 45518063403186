export function getBaseRoutes(orgSlug) {
  return [
    {
      path: `/ui/${orgSlug}/incidents`,
      name: 'incidents',
    },
    {
      path: `/client/${orgSlug}`,
      name: 'timeline',
    },
    {
      path: `/reports/${orgSlug}/post-incidents`,
      name: 'reports',
    },
    {
      path: `/dash/${orgSlug}/team-schedules`,
      name: 'teams',
    },
    {
      path: `/dash/${orgSlug}#/users`,
      name: 'users',
    },
    {
      path: `/dash/${orgSlug}#/advanced`,
      name: 'integrations',
    },
    {
      path: `/dash/${orgSlug}#/routekeys`,
      name: 'settings',
    },
  ]
}

export function getCurrentEnv(hostname) {
  const subdomain = hostname?.split('.')[0]
  switch (subdomain) {
    case 'demostportal':
      return 'demostage'
    case 'devstportal':
      return 'devstage'
    case 'stportal':
    case 'stage':
      return 'stage'
    case 'victorops-sandbox':
    case 'play':
      return 'sandbox'
    default:
      return 'production'
  }
}

export function getOrgs(data) {
  return data.orgs.map(org =>
    org.slug === data.orgslug ? { isCurrent: true, ...org } : org
  )
}

export function transformOnCall(
  username,
  {
    current_takes: currentOnCallTakes,
    next_off_call: currentOnCallShifts,
    next_on_call: nextOnCallShifts,
  }
) {
  return {
    current: [
      ...currentOnCallShifts
        .filter(
          ({ when, policySlug: ocsPolicySlug }) =>
            // This filter prevents duplicates in the on-call (server sends a current oncall and a take that are redundant)
            !currentOnCallTakes.find(
              ({ end_time, policySlug: octPolicySlug }) =>
                end_time === when && ocsPolicySlug === octPolicySlug
            )
        )
        .map(
          ({
            name: teamName,
            policyName,
            policySlug,
            slug: teamSlug,
            when,
          }) => ({
            policyName,
            policySlug,
            teamName,
            teamSlug,
            when,
            username,
          })
        ),
      ...currentOnCallTakes
        .filter(
          ({ override_user }) =>
            // This filter prevents a person being shown as being on-call for a shift that another user took from them
            !override_user || override_user === username
        )
        .map(
          ({
            end_time: when,
            name: teamName,
            policyName,
            policySlug,
            slug: teamSlug,
            original_user: originalUser,
            override_user: overrideUser,
          }) => ({
            originalUser,
            overrideUser,
            policyName,
            policySlug,
            teamName,
            teamSlug,
            username,
            when,
          })
        ),
    ],
    next: nextOnCallShifts.map(
      ({ name: teamName, policyName, policySlug, slug: teamSlug, when }) => ({
        policyName,
        policySlug,
        teamName,
        teamSlug,
        username,
        when,
      })
    ),
  }
}
