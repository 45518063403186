// vendor
import React from 'react'
import debug from 'debug'
import { Provider } from 'react-redux'
import { getFeatureFlags } from 'components/store/selectors'

import { fromJS } from 'immutable'

import { render, unmountComponentAtNode } from 'react-dom'

import { head, tail } from 'lodash'

import { Router, Route, browserHistory } from 'react-router'

// victorops
import { oLog } from '@victorops/utils'

import { fetch } from 'components/__utils/xhr'

import {
  hotpathProtocol,
  hotpathTimelineProtocol,
  batchedHistoryProtocol,
} from '@victorops/message-protocol'

// TODO: Temporary libs
import server from '../../app/server'
import vent from 'util/vent'

// lib
import xLog from 'util/extendedLog'
import frequencyBuffer from 'components/__utils/frequency'
import socket from 'components/socket'
import store from 'components/store/app-timeline'

import {
  clearTimelineMessages,
  getOrgMeta,
  getHelpContent,
  getIntegrationIcons,
  flushIncidents,
  incidentDetailsRequest,
  setTimelineEndOfHistory,
  setTimelineHasPendingTransaction,
  updateIncidents,
  updateIntegrations,
  updateTeams,
  updateUsers,
  updateOnCallList,
  updateOrgState,
  updateOnCall,
  updateRoutes,
  updateTeamsWithPolicies,
  updateTimelineFilters,
  updateBackboneContainerRendered,
  updateBackboneTimelineContainerActive,
} from 'components/store/actions'

// Protocol
import {
  IncidentsPane,
  incidentsProtocol,
  incidentsInitProtocol,
} from 'components/incidents'

import IncidentDetails from 'components/incident-details'
import StandaloneIncident from 'components/standalone-incident'

import { orgStateProtocol, orgStateInitProtocol } from 'components/orgstate'

import { usersProtocol } from 'components/users'

import { ManualIncident } from 'components/manual-incident'
import { onCallProtocol } from 'components/onCall'
import { RolodexDetailView } from 'components/rolodex'
import { VOTimeline } from 'components/timeline'

import { receiveTimelineMessageSequence } from './__entry-helpers'

import ModalConfirm from 'components/modal'

import BannerContainer from 'components/notifications/banner-container'

import mq from '../../app/server/mq'
import { NavBar } from './NavBar'

// Setup
// ---------------------------------------------------------------------------

/**
 * Debugging:
 *
 * Enable by VO_DEBUG.enable('VO:*')
 * Enable oLog VO_DEBUG.enable('VO_OBSERVABLE:*')
 */
global.VO_DEBUG = debug

const emitter = global.VO_SOCKET_EMITTER
const voSocket = socket(emitter)

const voStore = (global.VO_STORE = store(voSocket, server))

let currentFeatureFlags
function subscribeToFeatureFlags() {
  function select(state) {
    if (state.orgmeta) {
      return state.orgmeta.get('features')
    } else {
      return undefined // because the feature flags initialize to undefined, setting this to null would cause an unnecessary rerender.
    }
  }

  const previousFeatureFlags = currentFeatureFlags
  currentFeatureFlags = select(voStore.getState())

  if (previousFeatureFlags !== currentFeatureFlags) {
    headerDom()
  }
}
const unsubscribe = voStore.subscribe(subscribeToFeatureFlags)

voStore.dispatch(getOrgMeta())
voStore.dispatch(getHelpContent())
voStore.dispatch(getIntegrationIcons())

window.addEventListener('unhandledrejection', function(e) {
  debug('VO:unhandledrejection')(e)
})

const config = window.VO_CONFIG

// DOM
// ---------------------------------------------------------------------------
// Integrate with legacy Backbone appliaction.

function defaultDOM() {
  return {
    dom: (
      <Router history={browserHistory}>
        <Route path='/client/:client' component={IncidentsPane} />

        <Route
          path='/client/:client/createIncident'
          component={ManualIncident}
        />
      </Router>
    ),
    queryString: '.js-module-incidents',
  }
}

function headerDom(
  props = { panes: { timeline: true, people: true, incidents: true } }
) {
  const showCheckoutNewUILink = getFeatureFlags(voStore.getState()).get(
    'feature:teamincidentsdashboard'
  )

  if (!config) return
  return {
    dom: <NavBar config={config} panes={props.panes} />,
    queryString: '.primary-header-app',
  }
}

function incidentDetailsDOM(options) {
  const roomId = '*&tag=incident' + options.id
  voStore.dispatch(incidentDetailsRequest(parseInt(options.id, 10)))
  return {
    dom: (
      <IncidentDetails
        activeTab={options.activeTab}
        incidentId={options.id}
        roomId={roomId}
      />
    ),

    queryString: '.r-incident-details',
  }
}

function standaloneIncidentDOM(options) {
  const roomId = '*&tag=incident' + options.id
  voStore.dispatch(incidentDetailsRequest(parseInt(options.id, 10)))
  return {
    dom: <StandaloneIncident incidentId={options.id} roomId={roomId} />,

    queryString: '.r-standalone-incident',
  }
}

function timelineDOM(props) {
  const integrationsReq = fetch(
    `/api/v1/org/${window.VO_CONFIG.auth.org.slug}/integrations`
  )

  integrationsReq
    .then(integrations => {
      voStore.dispatch(updateIntegrations(integrations))
    })
    .catch(xLog)

  const { roomId, reactDomNode } = props

  return {
    dom: <VOTimeline roomId={roomId} />,
    queryString: reactDomNode,
  }
}

function rolodex(props) {
  return {
    dom: <RolodexDetailView {...props} />,
    queryString: '#rolodex-detail-view',
  }
}

function notificationsContainer(props) {
  return {
    dom: <BannerContainer banner={props.banner} />,
    queryString: '#notifications-container',
  }
}

function getDomConfig(key, props) {
  switch (key) {
    case 'header':
      return headerDom(props)
    case 'incidentDetails':
      return incidentDetailsDOM(props)
    case 'rolodexDetailView':
      return rolodex(props)
    case 'standaloneIncident':
      return standaloneIncidentDOM(props)
    case 'timeline':
      return timelineDOM(props)
    case 'notificationsContainer':
      return notificationsContainer(props)
    default:
      return defaultDOM()
  }
}

function attachDom(key, props) {
  try {
    const config = getDomConfig(key, props)
    const modalAttachToDOM = () => {
      if (key === 'header') {
        return <ModalConfirm />
      }
      return null
    }
    render(
      <Provider store={voStore}>
        <div style={{ height: '100%' }}>
          {config.dom}
          {modalAttachToDOM()}
        </div>
      </Provider>,
      document.querySelector(config.queryString)
    )
  } catch (e) {
    xLog('render', e)
  }
}

function detachDom(key, props) {
  unsubscribe()
  try {
    const config = getDomConfig(key, props)
    unmountComponentAtNode(document.querySelector(config.queryString))
  } catch (e) {
    xLog('de-render', e)
  }
}

function dispatch(action, callback) {
  voStore.dispatch(action)
  if (typeof callback === 'function') {
    callback()
  }
}

// Data
// ---------------------------------------------------------------------------

oLog('VO_OBSERVABLE:mapped', voSocket.read)

/**
 * First load
 *
 * The initial state of all known incidents is passed to the client as
 * a `SYSTEM_INCIDENT_STATE`. We shouldn't backpressure this, but we
 * should only auto-flush on the first load of the client, not on
 * subsequent WebSocket reconnects.
 */
let firstLoad = true

if (
  !(
    window.Storage &&
    window.sessionStorage.getItem('disable-timeline') === 'true'
  )
) {
  hotpathTimelineProtocol(voSocket.read).subscribe(
    function onNext(data) {
      receiveTimelineMessageSequence(
        data.roomId,
        data.messageSequence,
        voStore.dispatch
      )
    },
    function onError(err) {
      xLog('socketError', { socketAction: 'timeline-hotpath' })
      oLog('timeline hotpath failed:', err)
    },
    function onComplete() {
      oLog('completion called for timeline hotpath observer')
    }
  )

  batchedHistoryProtocol(voSocket.read).subscribe(
    function(data) {
      if (data && data.messageSequence.length === 0) {
        voStore.dispatch(
          setTimelineEndOfHistory({ roomId: data.roomId, endOfHistory: true })
        )
        voStore.dispatch(
          setTimelineHasPendingTransaction({
            roomId: data.roomId,
            transactionPending: false,
          })
        )
      } else if (data) {
        receiveTimelineMessageSequence(
          data.roomId,
          data.messageSequence,
          voStore.dispatch
        )
        voStore.dispatch(
          setTimelineHasPendingTransaction({
            roomId: data.roomId,
            transactionPending: false,
          })
        )
        mq.historyIsStarted = true
      }
    },
    function onError(err) {
      xLog('socketError', { socketAction: 'timeline-history-response' })
      oLog('TIMELINE_LIST_REPLY_MESSAGE failed:', err)
    },
    function onCompleted() {
      oLog('completion called for TIMELINE_LIST_REPLY_MESSAGE observer')
    }
  )
}

if (
  !(
    window.Storage &&
    window.sessionStorage.getItem('disable-incidents') === 'true'
  )
) {
  incidentsInitProtocol(voSocket.read).subscribe(function(incidents) {
    voStore.dispatch(updateIncidents(incidents))

    if (firstLoad) {
      voStore.dispatch(flushIncidents())
      firstLoad = false
    }
  })

  const incidents = incidentsProtocol(voSocket.read).share()

  frequencyBuffer(incidents, { id: 'incidents' }).subscribe(function(
    incidents
  ) {
    voStore.dispatch(updateIncidents(incidents))
  })
}

if (
  !(window.Storage && window.sessionStorage.getItem('disable-users') === 'true')
) {
  // Dispatch teams to store
  hotpathProtocol('STATE_NOTIFY_MESSAGE', voSocket.read)
    .filter(el => head(el) === 'GROUP_LIST')
    .subscribe(function(el) {
      const teams = head(tail(el))

      voStore.dispatch(updateTeams(teams))
    })

  // Dispatch teams to store
  hotpathProtocol('STATE_NOTIFY_MESSAGE', voSocket.read)
    .filter(el => head(el) === 'ONCALL_LIST')
    .subscribe(function(el) {
      const teams = head(tail(el))
      voStore.dispatch(updateOnCallList(teams))
    })

  // Dispatch users to store
  const users = usersProtocol(voSocket.read, voSocket.tSend).share()

  frequencyBuffer(users, { id: 'users' }).subscribe(async function(users) {
    // fetch users list with roles
    const usersList = await server.getUsers()
    const usersWithRoles = users.map(user => {
      const userMatch = usersList.find(u => u.username === user.USER_ID)
      const userRoles = userMatch ? userMatch.roles : []
      return { ...user, ROLES: userRoles }
    })
    voStore.dispatch(updateUsers(usersWithRoles))
  })
}

// Dispatch orgstate to store
orgStateInitProtocol(voSocket.read).subscribe(function(orgState) {
  voStore.dispatch(updateOrgState(orgState))
})

orgStateProtocol(voSocket.read).subscribe(function(orgState) {
  voStore.dispatch(updateOrgState(orgState))
})

// Dispatch onCall to store
onCallProtocol(voSocket.read).subscribe(function(onCall) {
  voStore.dispatch(updateOnCall(onCall))
})

function bindReduxToVent() {
  vent.on('reacttimeline:filter', data =>
    voStore.dispatch(updateTimelineFilters(data))
  )
  vent.on(
    'reacttimeline:insightsQuickFilterActive',
    (data, { storage, state }) => {
      voStore.dispatch(updateTimelineFilters(data))
      storage.get('deliveryInsights').set('state', state)
      if (state === 'on') {
        storage.get('chat').set('state', 'off')
      }
    }
  )
  vent.on('reacttimeline:chatQuickFilter', (data, { storage, state }) => {
    voStore.dispatch(updateTimelineFilters(data))
    storage.get('chat').set('state', state)
    if (state === 'on') {
      storage.get('deliveryInsights').set('state', 'off')
    }
  })
  vent.on('reacttimeline:backBoneContainerRendered', data =>
    voStore.dispatch(updateBackboneContainerRendered(data))
  )
  vent.on('reacttimeline:backboneTimelinePaneStateChange', data =>
    voStore.dispatch(updateBackboneTimelineContainerActive(data))
  )
  vent.on('reacttimeline:clearTimelineMessages', () =>
    voStore.dispatch(clearTimelineMessages())
  )
}

bindReduxToVent()

function hydrateHeader(data) {
  attachDom('header', data)
}

function hydrateTeamsWithPolicies() {
  const teamsWithPoliciesReq = fetch(
    `/api/v2/org/${config.orgslug}/teams?include=policies`
  )

  teamsWithPoliciesReq
    .then(policies => {
      voStore.dispatch(updateTeamsWithPolicies(policies))
    })
    .catch(xLog)
  return teamsWithPoliciesReq
}

function hydrateRoutes() {
  const routesReq = fetch(`/api/v2/org/${config.orgslug}/routes`)

  routesReq
    .then(routes => {
      voStore.dispatch(updateRoutes(routes))
    })
    .catch(xLog)
  return routesReq
}

// I don't think this is entirely necessary, but there could be a race without it.
// see js/util/route_keys.js
window.VO_TEAMPOLS = hydrateTeamsWithPolicies()
window.VO_ROUTES = hydrateRoutes()

// Exports
// ---------------------------------------------------------------------------

export default {
  hydrateHeader: hydrateHeader,
  attach: attachDom,
  detach: detachDom,
  dispatch: dispatch,
}
