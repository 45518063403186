import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
  NavBar as NavBarComponent,
  TrialBanner,
  themes,
} from '@victorops/victoria'
import {
  getBaseRoutes,
  getCurrentEnv,
  getOrgs,
  transformOnCall,
} from '../../util/navBar'

export const NavBar = ({ config, panes }) => {
  const isStakeholder = config.context.roles.some(
    role => role.name === 'stakeholder'
  )
  const isAdmin = config.isAdmin
  const billingState = config.billing.state
  const trialEnd = config.billing.trial_end
  const orgSlug = config.orgslug
  const email = config.context.user.primaryEmail
  const fullName = config.context.user.displayName
  const username = config.context.user.username
  const environment = getCurrentEnv(window.location.host)
  const authorizedOrgs = getOrgs(config)
  const onCallDetails = transformOnCall(username, config.nextOnCall)
  const onCall = onCallDetails.current
  const nextOnCall = onCallDetails.next
  const isTrial = config.billing.state === 'trial'
  return (
    <ThemeProvider theme={themes.voLight}>
      {isTrial && (
        <TrialBanner
          orgslug={config.orgslug}
          {...{ isAdmin, billingState, trialEnd }}
        />
      )}
      <NavBarComponent
        activeKey='timeline'
        iconLink={
          isStakeholder
            ? `/dash/${orgSlug}#/users/${username}`
            : `/ui/${orgSlug}/incidents`
        }
        routes={isStakeholder ? [] : getBaseRoutes(orgSlug)}
        panes={panes}
        whatsNewLocation={`/static/whats-new/whats-new.md?_=${Date.now()}`}
        {...{
          authorizedOrgs,
          email,
          environment,
          fullName,
          username,
          usernameDisplay: fullName,
          onCall,
          nextOnCall,
        }}
      />
    </ThemeProvider>
  )
}

NavBar.propTypes = {
  config: PropTypes.object.isRequired,
  panes: PropTypes.object.isRequired,
}
